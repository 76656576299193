import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import richText from "../serializers/richText"
import classNames from "classnames"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default () => {
  const data = useStaticQuery(graphql`
    {
      sanityDirectingPage(_id: { eq: "directingPage" }) {
        description: _rawDescription
        plays {
          year
          title
          writer
          theater
          _key
        }
        gallery {
          _key
          asset {
            url
          }
        }
      }
    }
  `)

  const { description, plays = [], gallery = [] } = data.sanityDirectingPage

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    fade: true,
    easing: "ease",
  }

  return (
    <Layout>
      <SEO title="Directing" />
      <section className="bio grid-container contained pt0 pt2--md">
        <div className="row">
          <aside className="col c5--lg">
            <div className="rich-text serif--sm serif--md--md">
              <BlockContent blocks={description} serializers={richText} />
            </div>
          </aside>
          {!!gallery.length && (
            <React.Fragment>
              <div className="col c1--lg show--lg" />
              <div className="col c6--lg">
                <div className='row align--center pr2--lg pt2 pt0--lg'>
                  <Slider className='col c11 c12--lg' {...sliderSettings}>
                    {gallery.map(item => (
                      <picture key={item._keyy}>
                        <source
                          srcSet={`${item.asset.url}?w=1000&auto=format&q=75`}
                          media="(min-width: 600px)"
                        />
                        <img
                          alt="Peter Coy Playwright"
                          className="db"
                          src={`${item.asset.url}?w=600&auto=format`}
                        />
                      </picture>
                    ))}
                  </Slider>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <div className="pt5 pt7--md pt10--lg">
          {plays.map((play, index) => {
            return (
              <div
                key={play._key}
                className={classNames("row", {
                  pt3: index > 0,
                })}
              >
                <span className="col c3 c1--md">
                  <p className="serif--sm serif--md--md">{play.year}</p>
                </span>
                <span className="col c6 c3--md">
                  <strong className="serif--sm serif--md--md">
                    {play.title}
                  </strong>
                  {play.writer && (
                    <em className="serif--sm serif--md--md hide--md">
                      by: {play.writer}
                    </em>
                  )}
                  {play.theater && (
                    <p className="serif--sm serif--md--md hide--md">
                      {play.theater}
                    </p>
                  )}
                </span>
                <span className="col c3--md show--md">
                  {play.writer && (
                    <em className="serif--sm serif--md--md">
                      by: {play.writer}
                    </em>
                  )}
                  {play.theater && (
                    <p className="serif--sm serif--md--md">{play.theater}</p>
                  )}
                </span>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}
